<template>
    <div class="">
        <xwHead />
        <div class="page_mask" v-if="$store.state.moduleLoading" v-loading="$store.state.moduleLoading"
            :element-loading-text="$t('遊戲資料獲取中、請稍後...')">

        </div>
        <div class="page_layout">
            <div class="mainNav">
                <div class="mainNav_In" v-if="$store.state.windowWidth > 1000">
                    <el-image :src="logo" class="head_logo"></el-image>
                    <ul class="nav_list">
                        <li v-for="(item, index) in menuArr" :key="index" v-tooltip="{ message: $t('請先登入賬號') }"
                            @click="pageJump(item.href)">
                            <div class="nav_items">
                                <div class="nav_name" :class="{ t_offer: item.href == '/activity-hall' }">
                                    <img class="iconActivity" v-if="index === 0"
                                        src="../../assets/images/xw/fire30X30_1.gif" alt="">
                                    <img class="icon_menu" v-if="item.icon_url" :src="item.icon_url" alt="">
                                    {{ item.name }}
                                </div>
                            </div>
                            <div class="nav_child_content" v-if="!noChildMenu.includes(item.href)">
                                <div class="gameBanner_wrapper">
                                    <div class="nav_child_item "
                                        :class="{ limit_height: item.children.length > 6, maintenance: childItem.extra == 'maintenance' }"
                                        v-tooltip="{ message: $t('請先登入賬號') }" @click.stop="openNewPage(childItem)"
                                        v-for="(childItem, key) in item.children" :key="key" :style="{
                                            '--menu_bg': `url(${childItem.icon_url})`,
                                            '--ps_x': bgDeviation(childItem.extra, item.children.length, key),
                                            '--max_w': childItem.extra ? `${childItem.extra}px` : '250px'
                                        }">
                                        <div class="gameBanner_logo_wrap">
                                            <img :src="childItem.icon_url" alt="">
                                        </div>
                                        <div class="gameBanner_logo_title">
                                            {{ childItem.name }}
                                        </div>
                                        <div v-if="childItem.extra == 'maintenance'" class="maintenance_card">
                                            {{ $t('維護中') }}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>


                <div class="mb_nav" v-else>
                    <ul class="mb_nav_list">
                        <li @click="changeMbMenu(index)" v-for="(item, index) in mbMenuArr" :key="index">
                            <div class="mb_nav_items" :class="{ active: index == mbMenuActive }">
                                <div class="mb_nav_items_content">
                                    <div class="mb_nav_items_icon"><img :src="item.icon_url" alt=""> </div>

                                    <span>{{ item.name }}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="mb_nav_detail" @scroll="handleScroll">
                        <div class="mb_nav_child" :class="{ maintenance: item.extra == 'maintenance' }"
                            v-tooltip="{ message: $t('請先登入賬號') }" @click.stop="openNewPage(item)"
                            v-for="(item, index) in mbMenuArr[mbMenuActive]?.children" :key="index">
                            <div v-if="item.extra == 'maintenance'" class="maintenance_card">
                                {{ $t('維護中') }}
                            </div>
                            <img class="menu_img" :src="item.icon_url" alt="">
                        </div>

                        <div class="scroll-icon" v-if="showScrollIcon && !isAtBottom">
                            <img src="@/assets/images/xw/arrow-more.gif" alt="">
                        </div>
                    </div>
                </div>
            </div>


            <div class="banner">
                <swiper class="turntable-thumbs" :options="turntableThumbs" ref="swiperThumbs">
                    <swiper-slide v-for="(item, index) in stakingthumbs" :key="`hot${index}`">
                        <div class="picture__link-box" @click.stop="pageJump(item.jump_url)">
                            <img v-if="item.img" v-lazy="item.img" alt="" class="banner_img">
                        </div>
                    </swiper-slide>

                </swiper>
                <div class="swiper-pagination"></div>
                <div class="runMsgArea">
                    <div class="runMsg">
                        <div class="icon_runMsg"></div>
                        <div class="runMsgDiv">
                            <merryGoRound />
                        </div>
                    </div>
                </div>

                <div class="rightBtn" v-if="$store.state.windowWidth > 1000">
                    <div class="customerServ">
                        <ul>
                            <li @click="platUrl(serviceData?.tg)" class="btn_CS_line">
                                <span>{{ $t('Telegram ') }}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="btn_CS_24h" @mouseover="showPopup('customerServDiv')"
                                @mouseleave="hidePopup('customerServDiv')">
                                <span>{{ $t('line客服') }}</span>
                                <div class="customerServDiv" ref="customerServDiv">
                                    <div class="servDivT" @click="platUrl(serviceData?.line)">Line客服</div>
                                    <div class="rightCsArrow"></div>
                                    <div class=csQrImg>
                                        <div class=csQrCode>
                                            <img class="lineQrCode" src="@/assets/images/xw/line_qr_code.png" alt="">
                                            <!-- <vue-qr v-if="serviceData.line" :size="85" :margin="0" :auto-color="true"
                                                :dot-scale="1" :text="serviceData?.line" /> -->
                                        </div>
                                        <span>＠500dpbkt</span>
                                    </div>
                                </div>
                            </li>

                        </ul>
                        <ul>
                            <li @click="platUrl(serviceData?.fb)" class="btn_CS_callBack ">
                                <span>{{ $t('FaceBook') }}</span>
                            </li>
                        </ul>
                        <ul>
                            <li @click="platUrl(serviceData?.ytb)" class="btn_CS_remote">
                                <span>{{ $t('YouTube') }}</span>
                            </li>
                        </ul>
                        <ul>
                            <li class="btn_CS_invitation" @mouseover="showPopup('invitationDiv')"
                                @mouseleave="hidePopup('invitationDiv')">
                                <span>{{ $t('邀请码') }}</span>
                                <div class="customerServDiv2" ref="invitationDiv">
                                    <div class="servDivT" @click="pageJump('/team-center')">{{
                                        $t('邀请鏈接') }}</div>
                                    <div v-if="$store.state.isLoginStatu" class="rightCsArrow2"></div>
                                    <div v-if="$store.state.isLoginStatu" class=csQrImg>
                                        <div class=csQrCode>
                                            <vue-qr v-if="$store.state.userInfo" :size="85" :margin="0"
                                                :auto-color="true" :dot-scale="1"
                                                :text="$store.state.userInfo?.invite_url" />
                                        </div>
                                        <span>code: </span>
                                        <span>{{ $store.state.userInfo?.rec_code || '' }}</span>
                                    </div>
                                    <div v-else style="color: #000;">{{ $t('未登入') }}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="picMenu_container" v-if="$store.state.windowWidth > 1000">
                <div class="picMenu">
                    <div class="category_title_wrap">
                        <h1>{{ $t('熱門遊戲') }}</h1>
                        <p>Welcome</p>
                    </div>
                    <div class="picMenu_content">
                        <div @click="openNewPage(item)" v-for="(item, index) in picMenuArr" :key="index"
                            class="btn_sportsLive" v-tooltip="{ message: $t('請先登入賬號') }">
                            <img :src="item.icon_url" alt="">
                        </div>
                    </div>
                </div>

                <div class="otherLink">
                    <!-- item.name == '投訴箱' ? openComplainBox() : pageJump(item.href) -->
                    <div @click="index == 0 ? pageJump(item.href) : () => { }" v-for="(item, index) in otherLinkArr"
                        :key="index" class="btn_ol_service" :class="{ comming_soon: item.extra == 'maintenance' }">
                        <div class="other_icon">
                            <img :src="item.icon_url" alt="">
                        </div>
                        <span>{{ item.name }}</span>
                        <div v-if="item.extra == 'maintenance'" class="soon_mask">{{ $t('即將推出') }}</div>
                    </div>
                </div>

                <div class="xw_footer">
                    <div class="under_list">
                        <ul class="footerLink">
                            <li v-for="(item, index) in underListArr" @click="pageJump(item.href)" :key="index">
                                <div class="nav_name">
                                    <span>{{ item.name }}</span>
                                    <!-- <span class="extra">{{ item.extra }}</span> -->
                                </div>
                            </li>
                        </ul>

                        <div class="rightSwitch" @click="footerShow = !footerShow">
                            <div>{{ $t('網站導覽') }}</div>
                            <span>|</span>
                            <div class="rightArrow" :class="{ rightArrowD: footerShow }"></div>
                        </div>
                    </div>

                    <div class="btmGuild" v-show="footerShow">
                        <div class="guildM">
                            <div class="guil_list">
                                <div v-for="(item, index) in footerListArr" :key="index" class="guilList">
                                    <div class="guilListT">
                                        {{ item.name }}
                                        <div class="guilListImg">
                                            <img :src="item.icon_url" alt="">
                                        </div>
                                    </div>
                                    <div class="guilListC">
                                        <button type="button" @click="openNewPage(child)"
                                            v-tooltip="{ message: $t('請先登入賬號') }"
                                            v-for="(child, cIndex) in item.children" :key="cIndex"
                                            class="guil_list_item">
                                            {{ child.name }}
                                        </button>
                                    </div>
                                </div>
                                <div class="guilList">
                                    <div class="guilListT">
                                        {{ $t('服務介紹') }}
                                        <div class="guilListImg">
                                            <!-- <img src="../../assets/images/xw/icon_indexImg.png" alt=""> -->
                                        </div>
                                    </div>
                                    <div class="guilListC">
                                        <button type="button" @click="pageJump('/customer')" class="guil_list_item">
                                            {{ $t('客服中心') }}
                                        </button>
                                        <button type="button"
                                            @click="!$store.state.isLoginStatu ? () => { } : pageJump('/xwNotice')"
                                            v-tooltip="{ message: $t('請先登入賬號') }" class="guil_list_item">
                                            {{ $t('公告專區') }}
                                        </button>
                                        <button type="button" @click="pageJump('/aboutUs')" class="guil_list_item">
                                            {{ $t('關於我們') }}
                                        </button>
                                        <button type="button" @click="pageJump('/xwClause/PRIVACY')"
                                            class="guil_list_item">
                                            {{ $t('隱私政策') }}
                                        </button>
                                        <button type="button" @click="pageJump('/xwClause/USERINFO')"
                                            v-tooltip="{ message: $t('請先登入賬號') }" class="guil_list_item">
                                            {{ $t('博彩條款') }}
                                        </button>
                                    </div>
                                </div>
                            </div>


                            <div class="rightMobile">
                                <div class="rq_box">
                                    <div style="width: 100px; height: 100px;background: #fff;">
                                        <vue-qr :logo-src="logo" v-if="downloadUrl" :size="100" :margin="0"
                                            :auto-color="true" :dot-scale="1" :text="downloadUrl" />
                                    </div>
                                </div>
                                <div class="guilListT leftPhoneT">
                                    <div class="icon_btmMobile">

                                    </div>
                                    XW BET {{ $t('手機版') }}
                                </div>
                                <span style="font-size: 12px;">{{ $t('鑫運娛樂 幸運人生') }}</span>
                            </div>


                        </div>

                    </div>

                    <div class="brandImg">
                        <div class="bottom_logo_wrap">
                            <div class="bottom_logo_content">
                                <img v-for="(img, index) in logoImages" :key="index" :src="img" alt="">
                                <img v-for="(img, index) in logoImages" :key="'clone-' + index" :src="img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="layui-layer-shade" v-if="complainShow">
            <div class="cashSkinPC">
                <div class="layer-close1" @click="closeComplain"></div>
                <div class="layer-title">{{ $t('投訴箱') }}</div>
                <div class="complantBox">
                    <div class="popup_hint">
                        {{ $t('您的批評與鼓勵將是我們前進的動力，我們歡迎您的任何意見與指教！') }}
                    </div>
                    <div class="popup_form ">
                        <div class="">{{ $t('内容') }}:</div>
                        <textarea class="txtContent" v-model="complainValue" maxlength="300"></textarea>
                    </div>

                </div>
                <div class="modal_footer">
                    <button class="modal_btn modal_cancel" @click="closeComplain">
                        {{ $t('離開') }}
                    </button>
                    <button class="modal_btn modal_ok" @click="closeComplain">
                        {{ $t('送出') }}
                    </button>
                </div>
            </div>
        </div>

        <div class="layui-layer-shade" v-if="callBackShow">
            <div class="cashSkinPC1">
                <div class="layer-close1" @click="closeCallBack"></div>
                <div class="layer-title">{{ $t('回電服務') }}</div>
                <div class="complantBox">
                    <div class="content_box">
                        <div class="content_title">{{ $t('選擇語言') }}</div>
                        <div class="contentbtn_box">
                            <div class="contentbtn_item" :class="{ active: langSelected == index }"
                                @click="langSelected = index" v-for="(item, index) in langList" :key="index">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                    <div class="content_box">
                        <div class="content_title">{{ $t('選擇問題') }}</div>
                        <div class="contentbtn_box">
                            <div class="content_q_item" :class="{ active: questionSelected == index }"
                                @click="questionSelected = index" v-for="(item, index) in questionList" :key="index">
                                <img :src="item.icon" alt="">
                                {{ item.label }}
                            </div>
                        </div>
                    </div>

                    <div class="popup_form ">
                        <textarea class="txtContent" v-model="questionValue" maxlength="300"
                            :placeholder="$t('請輸入您的問題（選填）')"></textarea>
                    </div>

                </div>
                <div class="modal_footer">
                    <button class="modal_btn modal_ok" @click="closeCallBack">
                        {{ $t('確認送出') }}
                    </button>
                </div>
            </div>
        </div>

        <div class="layui-layer-shade" v-if="transferShow" @click.self="closeTransfer">
            <div class="layui-layer-content">
                <div class="fast_transfer_box">
                    <div class="ft_head Head_bingo" :style="`background-image:url(${transferImg})`">
                        <div>
                            <p class="ft_head_title">{{ transferGameName }}</p>
                            <p>快速轉帳</p>
                        </div>
                        <div class="mod_close" @click="closeTransfer"></div>
                    </div>

                    <div class="ft_main">
                        <div class="popup_In_pc">
                            <div class="sub_list_box_a">
                                <div class="main_tt2 letSp">主帳戶</div>
                                <div class="MpPoint">{{ $store.state.userInfo.balance }}</div>
                            </div>

                            <div class="sub_list_box_a mt-13">
                                <div class="main_tt2 ">{{ transferGameName }}</div>
                                <div class="MpPoint">{{ Number(threeAmount) }}</div>
                            </div>

                            <div class="sub_list_box_a mt-13">
                                <div class="main_tt2 ">{{ $t('轉帳金額') }}</div>
                                <input type="text" v-model="transferAmount" @input="onInput" class="main_input"
                                    placeholder="請輸入金額">
                                <button type="button" class="btn_transfer"
                                    @click="transferAmount = Number($store.state.userInfo.balance).toFixed(0)">
                                    {{ $t('全轉') }}
                                </button>
                            </div>

                            <div class="sub_list_box_a mt-13">
                                <button @click="tripartiteTransfer" type="button" class="btn_style btn_style_l">
                                    {{ $t('進入遊戲') }}
                                </button>
                                <!-- <button @click="tripartiteTransfer" type="button" class="btn_style ">
                                    {{ $t('確認送出') }}
                                </button> -->
                            </div>
                        </div>

                        <div class="popup_In_mb">
                            <div class="formPopup_sum">
                                <div class="table_lay">
                                    <div class="flex_box">
                                        <div class="formTitle">{{ $t('主帳戶') }}</div>
                                        <div class="PopupMoney630">{{ $store.state.userInfo.balance }}</div>
                                    </div>
                                    <div class="flex_box">
                                        <div class="formTitle TransferB">{{ transferGameName }}</div>
                                        <div class="PopupMoney630 ">0</div>
                                    </div>

                                </div>
                                <div class="btnChange"
                                    @click="transferAmount = Number($store.state.userInfo.balance).toFixed(0)">
                                    {{ $t('全轉') }}
                                </div>
                            </div>
                            <ul class="transferBox">
                                <li>
                                    <input type="number" v-model="transferAmount" class="inputTBig" pattern="[0-9]*"
                                        disabled="disabled" maxlength="8" readonly placeholder="請輸入金額">
                                    <!-- <div class="butIngame" @click="tripartiteTransfer">
                                        {{ $t('轉帳') }}
                                    </div> -->
                                </li>

                                <li>
                                    <div class="numPlusArea">
                                        <div @click="addAmount(item)" v-for="(item, index) in tabKey" :key="index">
                                            +{{ item }}</div>
                                    </div>
                                </li>

                                <li>
                                    <div class="num_keyboard">
                                        <ul class="ul-f">
                                            <li v-for="(key, index) in keys" :key="index" @click="onKeyClick(key)">
                                                {{ key }}
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <div class="to_game" @click="tripartiteTransfer">
                                {{ $t('進入遊戲') }}
                            </div>
                        </div>
                        <div class="pop_load" v-if="transferLoading">{{ $t('金額轉入中，請不要關閉頁面...') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <gift></gift>
    </div>
</template>

<script>
import xwHead from "@/components/xwHead.vue";
import { game_login_game_api } from "@/api/cybSports";
import { banner_index_api, third_deposit } from "@/api/index";
import activitySwiper from "@/components/activitySwiper.vue";
import merryGoRound from '@/components/merryGoRound.vue';
import { serviceLink } from "@/utils/serviceLink.js";
import VueQr from 'vue-qr';
import { team_view_api } from '@/api/user.js'
import headerTip from '@/components/headerTip.vue'
import gift from '@/components/gift.vue'
export default {
    components: {
        activitySwiper,
        merryGoRound,
        VueQr,
        xwHead,
        gift
    },
    data() {
        return {
            transferShow: false,
            transferAmount: '',
            threeAmount: '',
            transferImg: '',
            transferGameName: '',
            transferGameHref: '',
            serviceData: {},
            lotteryNumber: 13282184.34,
            lotteryNumberLast: 13282184.34,
            animationKey: 0,
            logoImages: [
                require("../../assets/images/xw_logo/xw_logo1.png"),
                require("../../assets/images/xw_logo/xw_logo2.png"),
                require("../../assets/images/xw_logo/xw_logo3.png"),
                require("../../assets/images/xw_logo/xw_logo4.png"),
                require("../../assets/images/xw_logo/xw_logo5.png"),
                require("../../assets/images/xw_logo/xw_logo6.png"),
                require("../../assets/images/xw_logo/xw_logo7.png"),
                require("../../assets/images/xw_logo/xw_logo8.png"),
                require("../../assets/images/xw_logo/xw_logo9.png"),
                require("../../assets/images/xw_logo/xw_logo10.png"),
                require("../../assets/images/xw_logo/xw_logo11.png"),
                require("../../assets/images/xw_logo/xw_logo12.png"),
                require("../../assets/images/xw_logo/xw_logo13.png"),
                require("../../assets/images/xw_logo/xw_logo14.png"),
                require("../../assets/images/xw_logo/xw_logo15.png")
            ],
            complainShow: false,
            complainValue: '',
            callBackShow: false,
            langList: ['中文', 'English', 'Tiếng Việt', 'ภาษาไทย'],
            langSelected: 0,
            questionList: [
                {
                    label: '點數問題',
                    icon: require('@/assets/images/xw/i00.png')
                },
                {
                    label: '球賽問題',
                    icon: require('@/assets/images/xw/i01.png')
                },
                {
                    label: '真人遊戲',
                    icon: require('@/assets/images/xw/i02.png')
                },
                {
                    label: '彩球遊戲',
                    icon: require('@/assets/images/xw/i03.png')
                },
                {
                    label: '註冊問題',
                    icon: require('@/assets/images/xw/i04.png')
                },
                {
                    label: '電子遊戲',
                    icon: require('@/assets/images/xw/i05.png')
                },
            ],
            questionSelected: null,
            questionValue: '',
            logo: process.env.VUE_APP_SITE_LOGO,
            noChildMenu: ['/activity-hall', '/appDown'],
            menuArr: [],
            mbMenuArr: [],
            mbMenuActive: 0,
            stakingthumbs: [],
            turntableThumbs: {
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                },
                navigation: {
                    nextEl: ".splide__arrow--next",
                    prevEl: ".splide__arrow--prev"
                },
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 0,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                type: 'bullets',
            },
            picMenuArr: [],
            picColor: ['#ffa7da', '#5df6ff', '#bf92ff', '#fff200'],
            otherLinkArr: [],
            underListArr: [],
            footerListArr: [],
            footerShow: true,
            loadGame: false,
            downloadUrl: '',
            tabKey: [100, 500, 1000, 2000],
            keys: ['1', '2', '3', '4', '5', '←', '6', '7', '8', '9', '0'],
            nowGameUrl: '',
            nowTcid: 0,
            transferLoading: false,
            teamData: {},
            showScrollIcon: false,
            isAtBottom: false
        }
    },
    mounted() {
        this.getBanner()
        this.initDownUrl()
        this.startRolling()
        this.menuArr = this.$helper.get('xw_pc_top_menu') || []
        this.mbMenuArr = this.$helper.get('xw_mobile_game_menu') || []
        this.picMenuArr = this.$helper.get('xw_pc_middle_pic_menu') || []
        this.otherLinkArr = this.$helper.get('xw_pc_middle_menu') || []
        this.underListArr = this.$helper.get('xw_pc_footer_menu') || []
        this.footerListArr = this.$helper.get('xw_pc_site_menu') || []
        this.serviceData = serviceLink
        // this.$nextTick(() => {
        //     this.recalculateScrollIcon();
        // })
    },
    methods: {
        changeMbMenu(index){
            if(this.mbMenuActive == index) return
            this.mbMenuActive = index
            this.$nextTick(() => {
            this.recalculateScrollIcon();
        })
        },
        handleScroll(event) {
            const element = event.target;
            const isScrolledToBottom = element.scrollHeight - element.scrollTop - element.clientHeight <2;
            if (isScrolledToBottom) {
                this.isAtBottom = true;
                this.showScrollIcon = false;
            } else {
                this.isAtBottom = false;
                this.showScrollIcon = true;
            }
        },
        recalculateScrollIcon() {
            const element = this.$el.querySelector('.mb_nav_detail');
            if(element){
                if (element.scrollHeight > element.clientHeight) {
                this.showScrollIcon = true;
            } else {
                this.showScrollIcon = false;
            }
            }
        
        },
        addAmount(key) {
            const cacheAmount = Number(this.transferAmount) + key;
            if (String(cacheAmount).length > 8) return
            this.transferAmount = cacheAmount;
        },
        onKeyClick(key) {
            if (key === '←') {
                this.transferAmount = String(this.transferAmount).slice(0, -1);
            } else {
                if (this.transferAmount.length == 8) return
                this.transferAmount += key;
            }
        },
        closeTransfer() {
            if (this.transferLoading) return
            this.transferShow = false
            this.transferAmount = ''
        },
        startRolling() {
            this.$options.mountedAt = Date.now();
            let interval = setInterval(() => {
                this.lotteryNumberLast = this.lotteryNumber;
                this.lotteryNumber += parseFloat((5 + Math.random() * 5).toFixed(2));
                this.animationKey++; // 触发重新渲染

                let timeElapsed = Date.now() - this.$options.mountedAt;
                if (timeElapsed > 7 * 24 * 60 * 60 * 1000) {
                    this.lotteryNumber = 13282184.34;
                    this.lotteryNumberLast = this.lotteryNumber;
                    this.animationKey++; // 触发重新渲染
                    clearInterval(interval);
                }
            }, 3000);
        },
        openComplainBox() {
            this.complainShow = true;
        },
        closeComplain() {
            this.complainShow = false;
            this.complainValue = '';
        },
        closeCallBack() {
            this.callBackShow = false;
            this.langSelected = 0
            this.questionSelected = null
            this.questionValue = '';
        },
        initDownUrl() {
            let rule = this.$helper.get("rule");
            const ua = navigator.userAgent
            const isSafari = /Safari/.test(ua) && !/Chrome/.test(ua);
            if (isSafari) {
                this.downloadUrl = rule.ios_download_url
            } else {
                this.downloadUrl = rule.android_download_url
            }
        },
        bgDeviation(propDeviation, listLength, index) {
            let deviation = ''
            if (propDeviation) {
                deviation = Number(propDeviation) * index * -1 + 'px'
            } else {
                deviation = 1000 / listLength * index * -1 + 'px'
            }
            return 0
        },
        openNewPage(item) {
            if (item.extra == 'maintenance') {
                this.$xwDialog.open({
                    title: this.$t('訊息'),
                    content: this.$t('遊戲維護中，請稍後再試！'),
                    hasClose: false,
                    onClose: () => {
                    }
                });
                return
            }
            if (this.$store.state.isLoginStatu) {
                const match = item.href.match(/tc_id=(\d+)/);
                if (match) {
                    this.$router.push({ path: '/all-game', query: { tc_id: match[1] } })
                } else {
                    if (item.href.match(/id=(\d+)/)) {
                        this.transferGameName = item.name
                        this.transferGameHref = item.href
                        this.transferImg = item.icon_url
                        this.fetchGameUrl()
                    } else {
                        this.$xwDialog.open({
                            title: this.$t('訊息'),
                            content: this.$t('遊戲路徑錯誤，請聯絡客服'),
                            hasClose: false,
                            onClose: () => {
                            }
                        });
                    }

                }

            }
        },
        async tripartiteTransfer() {
            if (this.transferLoading) return
            if (this.transferAmount && this.transferAmount != 0) {
                if (Number(this.transferAmount) > Number(this.$store.state.userInfo.balance)) {
                    this.$xwDialog.open({
                        title: this.$t('訊息'),
                        content: this.$t('餘額不足'),
                        hasClose: false,
                        onClose: () => {
                        }
                    });
                    return
                }
                this.transferLoading = true
                const params = {
                    id: this.nowTcid,
                    amount: Number(this.transferAmount)
                }
                const response = await third_deposit(params)
                if (response.data.code == 1) {
                    const transamount = Number(response.data.data.transamount)
                    this.threeAmount = Number(this.threeAmount) + transamount
                    this.transferAmount = ''
                    this.reduceUserBalance(transamount)
                    this.toGamesPage()
                    this.transferLoading = false

                } else {
                    this.transferLoading = false
                    this.errorTips(response.data.msg)
                }
            } else {
                this.toGamesPage()
            }
        },
        toGamesPage() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            const isMobile = /android|iPad|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
            if (isMobile) {
                window.location.href = this.nowGameUrl
            } else {
                window.open(this.nowGameUrl)
            }
            this.transferShow = false
        },
        pageJump(path) {
            if (path == '#') return
            if (this.$store.state.windowWidth > 1000) {
                this.$store.commit('setOpenNewPage', true)
                this.openNewWindowAutoHeightName(`${window.location.origin}${this.$router.mode === 'hash' ? '/#' : ''}${path}`, 'ExampleWindow', 1002, 674)
            } else {
                this.$router.push({ path })
            }
        },
        async fetchGameUrl() {
            this.transferShow = false
            const match = this.transferGameHref.match(/id=(\d+)/);
            this.$store.commit("$vuexSetModuleLoadingState", true);
            let platform = this.isMobileFlag ? 2 : 1;
            await game_login_game_api({ platform, gid: match[1], amount: -1 }).then((res) => {
                this.loadGame = false;
                if (res && res.data.code == 1) {
                    this.nowGameUrl = res.data.data
                    this.nowTcid = res.data.tcid
                    this.threeAmount = res.data.three_amount
                    this.transferShow = true
                } else {
                    this.errorTips(res.data.msg)
                }
                this.transferAmount = ''
                this.$store.commit("$vuexSetModuleLoadingState", false);
            });
        },
        gotoUrl(url) {
            if (this.$store.state.isLoginStatu) {
                this.$router.push({ path: url })
            }
        },
        showMore(item) {
            // this.$refs.activityShowDiolog.show()
            this.activityItem = item
        },
        // 活动列表
        async getBanner() {
            const res = await banner_index_api();
            if (res && res.data.code == 1) {
                if (this._isMobile()) {
                    if (res.data.data["mobile"].length > 0) {
                        for (let key in res.data.data["mobile"]) {
                            if (
                                res.data.data["mobile"][key].lang == this.$helper.getLang() &&
                                res.data.data["mobile"][key].show_position == 1
                            ) {
                                this.stakingthumbs.push(res.data.data.mobile[key]);
                            }
                        }
                    }
                } else {
                    if (res.data.data["pc"].length > 0) {
                        for (let key in res.data.data["pc"]) {
                            if (
                                res.data.data["pc"][key].lang == this.$helper.getLang() &&
                                res.data.data["pc"][key].show_position == 1
                            ) {
                                this.stakingthumbs.push(res.data.data.pc[key]);
                            }
                        }
                    }
                }
            }
        },

        showPopup(demo) {
            const popup = this.$refs[demo];
            if (popup) {
                popup.style.display = 'block';
                setTimeout(() => {
                    popup.style.opacity = 1;
                    popup.style.visibility = 'visible';
                }, 10);
            }
        },
        hidePopup(demo) {
            const popup = this.$refs[demo];
            if (popup) {
                popup.style.opacity = 0;
                popup.style.visibility = 'hidden';
            }
        },
        onInput(event) {
            let value = event.target.value.replace(/[^0-9]/g, '');
            this.inputValue = value;
            event.target.value = value;
        },

    }
}


</script>

<style lang="less" scoped>
.page_layout {
    @media (max-width:1000px) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.mainNav {
    height: 70px;
    position: relative;
    box-sizing: border-box;

    @media (max-width:1000px) {
        height: auto;
        padding: 0 2%;
    }
}

.mainNav_In {
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

}

.head_logo {
    height: 60px;
    width: auto;
    object-fit: cover;

    img {
        height: 60px !important;
        width: auto !important;
        object-fit: cover !important;
    }
}

.nav_list {
    width: calc(100% - 178px);
    height: 100%;
    display: table;
    table-layout: fixed;
    font-size: 16px;
    font-weight: bold;

    li {

        display: table-cell;
        vertical-align: middle;
        text-align: center;
        height: 100%;
        color: #000;
        cursor: pointer;

        .nav_items {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .nav_name {
            width: 100%;
            color: #000;
            text-decoration: none;
            display: block;
            font-weight: bold;
            border-right: 1px solid #cccccc;

            &::after {
                content: '';
                height: 4px;
                background-color: transparent;
                position: absolute;
                width: 30%;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
                transition: width ease .3s;
                pointer-events: none;
            }
        }

        &:first-child {
            width: 13%;
        }

        &:last-child {
            .nav_name {
                border: none;
            }
        }

        &:hover {
            .nav_name {
                color: #00a05d;
            }

            .nav_name::after {
                background-color: #00a05d;
                width: 100%;
            }

            .nav_child_content {
                display: block;
            }
        }
    }
}

.mb_nav {
    margin-top: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2%;
}

.mb_nav_list {
    flex: 22%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    scrollbar-width: none;
    gap: 7px;

    li {
        position: relative;

        &:first-child {
            &::after {
                content: '';
                position: absolute;
                right: -2px;
                bottom: -4px;
                width: 36px;
                height: 36px;
                background: url('../../assets/images/xw/icon_hot.png');
                background-size: 100% 100%;
            }
        }
    }

}

.mb_nav_items {
    position: relative;
    background-color: #fefcc4;
    color: #535867;
    font-size: 12px;
    padding: 2px 1px 1px 2px;
    border-radius: 5px;
    font-weight: bold;
    border: 2px solid #9b6c3e;
    border-radius: 14px;
    box-shadow: 2px 2px 0px #a3988d;


}


.mb_nav_items_content {
    background-color: #ffbc27;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3px;
    border-radius: 12px;
    border-bottom: 2px solid #99754c;
    border-right: 2px solid #99754c;

    .mb_nav_items_icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            object-fit: cover;
        }
    }


    span {
        margin-top: 1px;
        font-size: 15px;
        font-weight: bold
    }
}

.mb_nav_items.active {
    border: 2px solid #a0495f;
    background-color: #fcc6cc;

    .mb_nav_items_content {
        background-color: #ff888a;
        border-bottom: 2px solid #c35058;
        border-right: 2px solid #c35058;
    }
}

.banner_img {
    width: 100%;
    height: 450px;
    object-fit: fill;

    @media (max-width:1000px) {
        max-height: 46vw;
    }
}


.mb_nav_detail {
    position: relative;
    flex: 78%;
    width: 100%;
    height: calc(460px + 11vw);
    overflow-y: auto;
}

.scroll-icon{
    position: fixed;
    bottom: 12.5%;
    right: 31%;
    width: 44px;
}

.mb_nav_child {
    position: relative;
    width: 100%;
    border-radius: 6px;
    height: fit-content;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 6px;
    user-select: none;

    img {
        min-width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.mb_nav_child.maintenance {
    pointer-events: none;
}

.maintenance_card {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2
}

.menu_logo {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    max-height: 60px;
    width: auto;
    z-index: 2;
}


.mb_nav_child.disabled {
    background: linear-gradient(135deg, #fff 0%, #dfe2e7 100%) !important;

    img {
        width: auto;
        height: 120px;
    }
}


.banner {
    position: relative;
    min-height: 430px;
    background-color: #525252;

    @media (max-width:1000px) {
        padding: 0 2%;
        background-color: transparent;
        min-height: 160px;
    }
}

.runMsgArea {
    background-color: rgba(0, 0, 0, .7);
    position: absolute;
    height: 45px;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    z-index: 10;

    @media (max-width:1000px) {
        // background-color: #207bb4;
        height: 36px;
        // position: static;
        width: calc(96%);
        margin: 0 auto;
    }
}

.runMsg {
    display: flex;
    height: 100%;
    // left: calc(50% - 500px);
    bottom: 0;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

}

.icon_runMsg {
    width: 45px;
    height: 100%;
    float: left;
    background: url('../../assets/images/xw/icon_runMsg.svg') 12px 50% / 21px no-repeat;
}

.runMsgDiv {
    // margin: 0 20px 0 10px;
    flex: 1;
    color: #fff;
    font-size: 14px;
    line-height: 50px;
    width: 100%;
    overflow: hidden;
}

.picMenu_container {
    width: 100%;
    background: url('../../assets/images/xw/main-bg-light.jpg') no-repeat;
    background-size: cover;
    background-position: top left;
}

.picMenu {
    margin: 0 auto;
    padding-top: 30px;
    width: 100%;
    max-width: 1200px;
}

.category_title_wrap {
    width: 100%;
    text-transform: uppercase;
    text-align: center;

    h1 {
        font-weight: 400;
        font-family: "Roboto", sans-serif !important;
        font-size: 48px;
        line-height: 50px;
        color: #677076;
        letter-spacing: normal;
    }

    p {
        width: 100%;
        letter-spacing: 20px;
        color: #c72020;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;

        &::before {
            content: " ";
            margin-right: 10px;
            width: calc(50% - 80px);
            height: 1px;
            background: linear-gradient(180deg, rgba(199, 32, 32, 0), #9b1919);
        }

        &::after {
            content: " ";
            width: calc(50% - 80px);
            height: 1px;
            background: linear-gradient(180deg, rgba(199, 32, 32, 0), #9b1919);
            margin-left: -10px;
        }
    }
}

a {
    font-style: normal;
    text-decoration: none;
}


.picMenu_content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
}


.btn_sportsLive {
    width: 32%;
    height: 100%;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    vertical-align: bottom;
    background-size: cover;

    &:hover {
        img {
            animation: zoom 0.5s forwards;
        }
    }
}

@keyframes zoom {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.otherLink {
    display: table;
    border-spacing: 6px;
    width: 845px;
    margin: 27px auto 0 auto;
}

.btn_ol_service {
    display: table-cell;
    height: 55px;
    vertical-align: middle;
    color: #666;
    font-size: 16px;
    padding-left: 78px;
    letter-spacing: 2px;
    position: relative;
    white-space: nowrap;
    cursor: pointer;
    transition: all ease .3s;
    box-sizing: border-box;
    margin-right: 4px;

    .other_icon {
        width: 55px;
        height: 55px;
        position: absolute;
        top: 0;
        left: 13px;
        margin: auto 0;
        transition: all ease .3s;
    }

    &:hover {
        color: #007745;
        padding-bottom: 16px;

        .other_icon {
            top: -8px;
        }
    }
}

.comming_soon {
    position: relative;
    cursor: no-drop;

    &:hover {
        padding-bottom: 0;

        .other_icon {
            top: 0;
        }
    }
}

.soon_mask {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(49, 69, 79, 0.8);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.xw_footer {
    background-color: #242424;
    margin-top: 28px;
    min-width: 1200px;
}

.under_list {
    width: 1000px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footerLink {
    height: 100%;
    display: table;
    table-layout: fixed;
    height: 58px;
    color: #808080;

    li {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        height: 100%;

        cursor: pointer;

        .nav_name {
            width: 100%;
            text-decoration: none;
            display: flex;
            font-weight: bold;
            padding: 0 8px;
            border-right: 1px solid #808080;
            gap: 4px;
        }

        &:last-child {
            .nav_name {
                border: none;
            }
        }

        &:hover {
            .nav_name {
                color: #fff;
            }
        }
    }
}

.extra {
    color: #fff;
    font-weight: 400;
}

.rightSwitch {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
        color: #525252;
        margin: 0 10px;
    }
}

.rightArrow {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 8px solid #1675a3;
}

.rightArrowD {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 8px solid #1675a3;
    border-top: 0;
}

.btmGuild {
    width: 100%;
    border-top: 1px solid #484848;
    padding-bottom: 10px;
    background-color: #777;
}

.guildM {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.guil_list {
    display: flex;
    align-items: flex-start;
}

.guilList {
    margin: 4px 5px 5px 5px;
}

.guilListT {
    color: #fff;
    line-height: 39px;
    border-bottom: 1px solid #ccc;
    position: relative;
    padding-left: 30px;
}

.guilListImg {
    position: absolute;
    top: 7px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.guilListC {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7px;
}

.guil_list_item {
    line-height: 16px;
    color: #ccc;
    width: 100%;
    text-align: left;
    padding: 4px 0 4px 32px;
}

.rightMobile {
    margin-top: 15px;
    text-align: center;
}

.rq_box {
    width: 110px;
    padding: 5px;
    height: 110px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 10px;
}

.icon_btmMobile {
    background: url('../../assets/images/xw/icon_indexImg.png') no-repeat -196px -793px;
    width: 23px;
    height: 21px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 1px;
    margin: auto 0;
}

.leftPhoneT {
    padding-left: 23px;
    font-size: 13px;
    border: none;
    line-height: 39px;
    margin: 0 0 5px 0;
    border-bottom: 1px solid #ccc;
}

.brandImg {
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #484848;

    .bottom_logo_wrap {
        width: 100%;
        height: 100%;
        max-width: 1000px;
        margin: 0 auto;
        padding: 8px;
        overflow-x: hidden;

        .bottom_logo_content {
            height: 100%;
            display: flex;
            align-items: center;
            user-select: none;
            animation: scroll 20s linear infinite;

            img {
                width: auto;
                height: 100%;
                object-fit: cover;
            }
        }

    }

}

@keyframes scroll {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-50%);
    }
}

.gameBanner_wrapper {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
}

.nav_child_content {
    position: absolute;
    width: fit-content;
    min-width: 500px;
    // height: 340px;
    top: 70px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2;
    overflow: hidden;
    display: none;
    background-color: rgba(255, 255, 255, .95);
    cursor: default;
}

.gameBanneyir_wrapper {
    margin: 0 auto;
    position: relative;
    width: 1000px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.nav_child_item {
    position: relative;
    flex: 1;
    width: 100%;
    max-width: 160px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    gap: 4px;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: calc(100% - 8px) auto;
    }

    &:hover {
        background: linear-gradient(to bottom, rgba(219, 232, 251, 1) 0%, rgba(219, 232, 251, .2) 100%);

        &::after {
            transform: scale(1.08, 1.08)
        }

        .gameBanner_logo_title {
            font-size: 18px !important;
            color: #0f679a;
        }
    }


}

.nav_child_item.maintenance {
    pointer-events: none;
}

.limit_height {
    height: 50% !important;

    &::after {
        background-position: center 10px;
    }
}

.gameBanner_logo_wrap {
    width: 160px;
    height: 160px;

    img {
        width: 100%;
        height: 100%;
    }
}

.gameBanner_logo_title {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    height: 30px;
    color: #000;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-wrap: nowrap;
}

.swiper-pagination {
    position: absolute;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    bottom: 52px;
    /* 根据需要调整位置 */
    width: 100%;

    @media (max-width:1000px) {
        bottom: 40px;
    }
}


.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    opacity: 0.8;
    transition: .3s opacity, .3s background-color;
}

::v-deep .swiper-pagination-bullet-active {
    opacity: 1;
    background: #fff !important;
}

.page_mask {
    position: fixed;
    width: 100vw;
    height: var(--vh);
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #00a05d;
    padding-top: 80px;
}

.rightBtn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
    width: 0;
}

.customerServ {
    display: table;
    table-layout: fixed;
    width: 80px;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    height: 405px;

    ul {
        display: table-row;
        list-style: none;

        li {
            display: table-cell;
            position: relative;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            transition: all ease .2s;

            &:hover {
                background-color: rgba(255, 255, 255, .2)
            }

            &::before {
                content: '';
                width: 32px;
                height: 32px;
                display: block;
                margin: 0 auto;
                background-size: auto 100%;
                transform: translateY(-10px);
            }

            &::after {
                content: '';
                width: 76px;
                height: 1px;
                background-color: rgba(255, 255, 255, .3);
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
    }
}

.btn_CS_24h {
    position: relative;

    &::before {
        background: url('../../assets/images/xw/line.png') no-repeat;
    }
}

.customerServDiv {
    position: absolute;
    right: 100px;
    background-color: rgba(255, 255, 255, .7);
    top: 0;
    width: 170px;
    display: none;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
}

.customerServDiv2 {
    position: absolute;
    right: 100px;
    background-color: rgba(255, 255, 255, .7);
    bottom: 10px;
    width: 170px;
    display: none;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
}

.servDivT {
    height: 40px;
    line-height: 40px;
    border-bottom: 2px solid #fff;
    font-size: 14px;
    color: #1a7ecc;
    position: relative;
    text-decoration: underline;
}

.rightCsArrow {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid rgba(255, 255, 255, .7);
    position: absolute;
    top: 10px;
    right: -10px;
}

.rightCsArrow2 {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid rgba(255, 255, 255, .7);
    position: absolute;
    bottom: 15px;
    right: -10px;
}

.csQrImg {
    padding: 10px 0;
    color: #000;
}

.lineQrCode{
    width: 86px;
    height: auto
}

.btn_CS_line {
    &::before {
        background: url('../../assets/images/xw/tg.png') no-repeat;
    }
}

.btn_CS_callBack {
    &::before {
        background: url('../../assets/images/xw/Facebook.png') no-repeat;
    }
}

.btn_CS_remote {
    &::before {
        background: url('../../assets/images/xw/YouTube.png') no-repeat;
    }
}

.btn_CS_invitation {
    &::before {
        background: url('../../assets/images/xw/invitation.png') no-repeat;
    }
}


.iconActivity {
    width: auto;
    height: auto;
    max-height: 24px;
    max-width: 28px;
    margin-right: 1px;
    margin-top: -5px;
}

.icon_menu {
    width: 12px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin: -2px 5px -2px 0;
}

.t_offer {
    color: #ac35de !important;
}




.layui-layer-shade {
    position: fixed;
    pointer-events: auto;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--vh);
    background-color: rgba(0, 0, 0, .46);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3%;
}

.cashSkinPC {
    position: relative;
    width: 100%;
    max-width: 443px;
    // height: 447px;
    background-color: #fff;
    color: #000;
    border-radius: 10px;
}

.cashSkinPC1 {
    position: relative;
    width: 100%;
    max-width: 511px;
    background-color: #fff;
    color: #000;
    border-radius: 10px;
}


.layer-title {
    background-color: unset;
    text-align: center;
    font-weight: bold;
    height: auto;
    line-height: inherit;
    border-bottom: none;
    height: 56px;
    line-height: 58px;
    color: #000;
    display: block;
    margin: 0 auto;
    width: 100%;
    padding: 0 !important;
}

.layer-close1 {
    background: url('../../assets/images/xw/btn_close2.svg') no-repeat center;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 22px !important;
    height: 22px !important;
    cursor: pointer;
    opacity: .2;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}


.complantBox {
    width: 84%;
    margin: 0 auto;
}

.popup_hint {
    margin: 0 auto 6px;
    line-height: 25px;
    padding: 0 10 15px !important;
    color: #000;
    text-align: left;
    font-size: 15px;
}

.popup_form {
    padding: 16px 0;
}

.txtContent {
    border: 1px solid #c2c2c2;
    outline: none;
    padding: 2%;
    font-size: 15px;
    width: 100%;
    height: 80px;
}

.modal_footer {
    display: flex;
    border-top: 1px solid #eee;
    padding: unset;
}

.modal_btn {
    flex: 1;
    width: 50%;
    border-color: unset;
    background-color: unset;
    height: 45px;
    line-height: normal !important;
    outline: none;

    &:hover {
        background-color: #e5e5e5;
    }
}

.modal_ok {
    color: #3f9eff;
    width: 100%;
}

.modal_cancel {
    color: #a1a1a1;
    border-right: 1px solid #eee;
}

.content_box {
    width: 100%;
    display: table;
    position: relative;
    bottom: 11px;
}

.content_title {
    width: 100%;
    font-size: 15px;
    text-align: left;
    color: #000;
    line-height: 38px;
    font-weight: 700;
}

.contentbtn_box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
}

.contentbtn_item {
    cursor: pointer;
    width: 98px;
    height: 39.6px;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    line-height: 38px;
    text-align: center;
    border-radius: 5px;
    color: #000;
}

.contentbtn_item.active {
    border: 1px solid #45b5d9;
    background: #e3f7fb;
}

.content_q_item {
    cursor: pointer;
    position: relative;
    width: 208px;
    height: 39.6px;
    padding-left: 42px;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    line-height: 38px;
    text-align: center;
    border-radius: 5px;
    color: #000;

    @media (max-width:768px) {
        width: 142px;
        padding-left: 38px;
    }

    img {
        position: absolute;
        left: 30px;
        top: 5px;
        width: 30px;
        height: 30px;

        @media (max-width:768px) {
            left: 15px;
        }
    }
}

.content_q_item.active {
    border: 1px solid #45b5d9;
    background: #e3f7fb;
}

.jackpotBox {
    height: 200%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 0;
}

.jackpotBAC {
    display: flex;
    align-items: center;
    position: relative;
    height: 50%;
    z-index: 1;
    padding: 0 13px 0 17px;

    &::before {
        content: '';
        transform: skew(20deg);
        -webkit-transform: skew(20deg);
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(to right, #052d66 0%, #0f4999 50%, #3373cc 100%);
    }
}

.jackpotNum {
    height: 32px;
    width: 236px;
    display: flex;
    align-items: center;
    padding: 0 38px;
    font-size: 22px;
    font-weight: bold;
    background: linear-gradient(45deg, #f3ec78, #af4261);
    -webkit-background-clip: text;
    color: transparent;
    font-style: italic;
}




.layui-layer-content {
    z-index: 2000;
    width: 100%;
    max-width: 508px;
    height: 440px;

    @media (max-width:1000px) {
        height: auto;
    }
}

.fast_transfer_box {
    font-family: "Microsoft JhengHei";
    height: 440px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: #f5f5f5;
    font-size: 16px;
    border-radius: 4px;

    @media (max-width:1000px) {
        border-radius: 10px;
        overflow: hidden;
        background-color: #fff;
        height: auto;
    }
}

.Head_bingo {
    height: 105px;
    width: 100%;
    position: relative;
    background-color: #00a05d;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 28px 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 16px;
        text-align: center;
    }

    @media (max-width:1000px) {
        font-size: 16px;
        padding: 0 12px;
        background-color: #fff;
        color: #3783c1;
        line-height: 38px;
        height: 38px;
        box-sizing: border-box;
        position: relative;
        font-weight: bold;
        background-image: none !important;

        &::after {
            content: '';
            margin: 0 auto;
            display: block;
            border-bottom: 1px solid #3783c1;
            position: absolute;
            bottom: 0;
            width: calc(100% - 24px);
            z-index: 1;
        }
    }
}

.ft_head_title {
    font-size: 28px !important;
    font-weight: bold;

    @media (max-width:1000px) {
        display: none;
    }
}

.mod_close {
    width: 42px;
    height: 42px;
    background: url('../../assets/images/xw/btn_close.svg') no-repeat center;
    background-size: 50%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    opacity: .5;

    @media (max-width:1000px) {
        width: 38px;
        height: 38px;
        z-index: 1;
        opacity: .7;
        background: url('../../assets/images/xw/btn_close2.svg') no-repeat center;
        background-size: 38% auto;
    }

    &:hover {
        opacity: 1;
    }
}

.ft_main {
    width: 100%;
    margin-top: 40px;

    @media (max-width:1000px) {
        margin-top: 0;
    }
}

.popup_In_pc {
    display: block;

    @media (max-width:1000px) {
        display: none;
    }
}

.popup_In_mb {
    display: none;

    @media (max-width:1000px) {
        display: block;
        background-color: #fff;
        padding: 11px 12px !important;
    }
}

.formPopup_sum {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.table_lay {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #000;
}

.flex_box {
    display: flex;
    align-items: center;
}

.formTitle {
    font-size: 16px;
    line-height: 25px;
    height: auto;
    width: 43%;
    margin-bottom: 0;
    padding-left: 8px;
    box-sizing: border-box;
    font-weight: bold;
}

.TransferB {
    color: #3783c1;
}

.PopupMoney630 {
    width: 120px;
    line-height: 25px;
    width: 57%;
    color: #00ae08;
    font-size: 18px;
    font-weight: bold;
}

.btnChange {
    color: #fff;
    background-color: #ff9c00;
    font-size: 16px;
    width: 27%;
    height: 38px;
    font-weight: inherit;
    border-radius: 2px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.transferBox {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        position: relative;
        display: table;
        width: 100%;
        margin-bottom: 10px;
    }
}

.inputTBig {
    pointer-events: none;
    caret-color: #000;
    width: 100% !important;
    height: 38px;
    font-size: 18px;
    background-color: #ddd;
    outline: none;
    border-radius: 3px;
    border: 1px solid #ddd;
    color: #000;
    opacity: 1;
    cursor: auto;
    user-select: none;
}

.butIngame {
    background-color: #45b5d9;
    color: #fff;
    font-size: 20px;
    float: right;
    height: 38px;
    font-weight: inherit;
    border-radius: 2px;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 27%;
    cursor: pointer;
}

.numPlusArea {
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    background-color: #f3f3f3;
    height: 42px;
    line-height: 42px;
    overflow: hidden;
    font-size: 16px;
    display: flex;
    cursor: pointer;

    div {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;

        &+div {
            border-left: 1px solid #dfdfdf;
        }
    }
}

.num_keyboard {
    width: 100%;
    background: #fff;
    border: 1px solid #2682d5;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}

.ul-f {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    &::after {
        display: block;
        content: "";
        clear: both;
    }
}


.num_keyboard .ul-f li {
    text-align: center;
    width: calc(100% / 6);
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-size: 32px;
    color: #2682d5;
    border-right: 1px solid #2682d5;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    float: left;
    display: block;
}

.ul-f li:nth-child(-n+6) {
    border-bottom: 1px solid #2682d5;
}

.num_keyboard .ul-f li:nth-child(6) {
    border-right: 1px solid transparent;
}


.num_keyboard .ul-f li:last-child {
    width: calc(100% / 3);
    height: 50%;
    border-right: 1px solid transparent;
}

.to_game {
    margin-top: 16px;
    color: #fff;
    cursor: pointer;
    padding: 0;
    border: 0;
    text-align: center;
    background-color: #ff7800;
    box-sizing: border-box;
    font-weight: bold;
    border-radius: 5px !important;
    width: 100%;
    padding: 10px;
}


.sub_list_box_a {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
}

.mt-13 {
    margin-top: 13px;
}

.fast_transfer_box .ft_main .main_tt2 {
    font-size: 16px;
    font-weight: normal;
    color: #3f4444;
    height: 48px;
    width: 75px;
    text-align: left;
    line-height: 48px;
    letter-spacing: 1px;
    margin-left: 68px;
}

.main_tt2.letSp {
    letter-spacing: 9px;
}


.MpPoint {
    height: 48px;
    width: 296px;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    line-height: 48px;
    font-size: 22px;
    font-weight: bold;
    color: #009e29;
    text-align: left;
    padding-left: 25px;
    padding-right: 0;
    border-radius: 2px;
    position: relative;
}

.main_input {
    outline-color: #9dbff6;
    height: 48px;
    width: 208px;
    line-height: 48px;
    font-size: 20px;
    text-align: left;
    padding-left: 25px;
    border-radius: 5px;
    margin-right: 10px;
    font-weight: 700;
    font-family: 'Microsoft Jhenghei';
    border: #ccc 1px solid;
    background-color: #f5f5f5;
    color: #888;
}

.btn_transfer {
    height: 50px;
    color: #fff;
    font-size: 16px;
    width: 80px;
    margin-top: 1px;
    text-align: center;
    border-radius: 3px;
    border: #ccc 1px solid;
    background-color: #f5f5f5;
    color: #888;
    cursor: pointer;
}

.btn_style {
    height: 50px;
    color: #fff;
    font-size: 16px;
    width: 372px;
    margin-top: 1px;
    margin-left: 16px;
    text-align: center;
    border-radius: 3px;
    background-color: #f5f5f5;
    border: #ccc 1px solid;
    background-color: #f5f5f5;
    color: #888;
    cursor: pointer;
}

.btn_style_l {
    border: 1px solid #62b57c;
    color: #62b57c;
    margin-left: 68px;

    &:hover {
        background-color: #e3f2e7;
        color: #4c8c60;
    }
}

.pop_load {
    margin-top: 10px;
    color: #f00;
    text-align: center;
}
</style>